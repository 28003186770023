<template>
  <v-card tile flat class="blue-grey lighten-5">
    <v-card-title>Add/Edit Customer</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Name*</v-col>
          <v-col cols="1" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.salutation"
              label="Mr./Mrs"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.first_name"
              label="First Name"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.last_name"
              label="Last Name"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Email</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.email"
              label="Email"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Phone</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.phone"
              label="Phone"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Printable Name</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.printable_name"
              label="Printable Name"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="mb-2 mt-2">
          <v-col cols="2" class="text-right align-self-center black--text">Customer Address</v-col>
          <v-col cols="6" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.customer_address.address"
              label="Street address"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2"></v-col>
          <v-col cols="3" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.customer_address.city"
              label="City"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-text-field
              hide-details
              v-model="customer.customer_address.state"
              label="State"
              placeholder="State"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2"></v-col>
          <v-col cols="3" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.customer_address.country"
              label="Country"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-text-field
              hide-details
              v-model="customer.customer_address.postal_code"
              label="Postal code"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2 mt-4">
          <v-col cols="2" class="text-right align-self-center black--text">Billing Address</v-col>
          <v-col cols="6" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.billing_address.address"
              label="Street address"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2"></v-col>
          <v-col cols="3" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.billing_address.city"
              label="City"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-text-field
              hide-details
              v-model="customer.billing_address.state"
              label="State"
              placeholder="State"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2"></v-col>
          <v-col cols="3" class="ml-3">
            <v-text-field
              hide-details
              v-model="customer.billing_address.country"
              label="Country"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pl-3">
            <v-text-field
              hide-details
              v-model="customer.billing_address.postal_code"
              label="Postal code"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="mb-2 mt-4">
          <v-col cols="2" class="text-right align-self-center black--text">Meters</v-col>
          <v-col class="ml-3">
            <v-chip
              pill
              class="ma-1"
              v-for="gateway in gateways"
              :key="gateway.id"
              @click="onGatewayClick(gateway.id)"
            >
              <v-avatar left color="primary">{{gatewayMeterCount(gateway.id)}}</v-avatar>
              {{gateway.name}}
            </v-chip>
          </v-col>
          <v-dialog v-model="showMetersDialog" max-width="1200">
            <v-card class="blue-grey lighten-5">
              <v-card-title>
                <span class="headline">Add/remove meters</span>
                <v-spacer></v-spacer>
                <v-avatar color="primary" size="32">
                  <span>{{selectedMeters.length}}</span>
                </v-avatar>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <v-row no-gutters>
                  <v-col
                    cols="2"
                    class="text-center align-self-center black--text body-1"
                  >Available Meters</v-col>
                  <v-col v-if="dialogMeters.length !=0">
                    <v-chip-group multiple column active-class="primary--text">
                      <v-chip
                        label
                        color="secondary"
                        v-for="(meter) in dialogMeters"
                        :key="meter.id"
                        @click="onMeterClick(meter)"
                      >{{ meter.name || meter.id}}</v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col v-else>
                    <span>No meters available</span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-4">
                  <v-col
                    cols="2"
                    class="text-center align-self-center black--text body-1"
                  >Selected Meters</v-col>
                  <v-col v-if="selectedMeters.length != 0">
                    <v-chip-group column text-color="white">
                      <v-chip
                        color="primary"
                        label
                        v-for="meter in selectedMeters"
                        :key="'selected'+meter.id"
                        close
                        @click:close="onMeterClose(meter)"
                      >{{ meter.name || meter.id }}</v-chip>
                    </v-chip-group>
                  </v-col>
                  <v-col v-else>
                    <span>No meters selected</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showMetersDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn depressed color="green" class="white--text" @click="saveCustomer">Save</v-btn>
      <v-btn depressed color="red" class="white--text" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "customer",
  components: {},
  data() {
    return {
      customer: {
        salutation: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        org_idx: localStorage.getItem("orgId"),
        printable_name: null,
        customer_address: {
          address: null,
          city: null,
          state: null,
          country: null,
          postal_code: null
        },
        billing_address: {
          address: null,
          city: null,
          state: null,
          country: null,
          postal_code: null
        }
      },
      showMetersDialog: false,
      dialogMeters: [],
      selectedMeters: [],
      gatewaySelectedMeters: [],
      selectedGateway: ""
    };
  },
  computed: {
    gateways() {
      return this.$store.getters["MDMStore/getterOrgGateways"];
    }
  },
  watch: {},
  beforeCreate() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      var self = vm;
      if (self.$router.currentRoute.name == "editcustomer") {
        self.$store
          .dispatch({
            type: "MDMStore/getCustomerInfo",
            custId: self.$router.currentRoute.params.custId
          })
          .then(data => {
            self.customer = Object.assign({}, data);
            self.gatewaySelectedMeters = data.meters.map(meter => ({
              gateway: meter.gateway_idx,
              meter: meter
            }));
          });
      }
    });
  },
  mounted() {
    var self = this;
    this.$store.dispatch({
      type: "MDMStore/getOrgDetails"
    });
    this.$store.dispatch({
      type: "MDMStore/getMeters"
    });
    if (self.$router.currentRoute.name == "editcustomer") {
      self.$store
        .dispatch({
          type: "MDMStore/getCustomerInfo",
          custId: self.$router.currentRoute.params.custId
        })
        .then(data => {
          self.customer = Object.assign({}, data);
        });
    }
  },
  methods: {
    saveCustomer() {
      var self = this;
      if (this.$router.currentRoute.name == "editcustomer") {
        this.$store
          .dispatch({
            type: "MDMStore/updateCustomer",
            customer: this.customer,
            meters: this.gatewaySelectedMeters.map(m => m.meter.id)
          })
          .then(data => {
            if (data.success) {
              self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Customer updated successfully", status:"success"});
              self.$router.push({path:"/customers/customer/"+this.customer.id});
            }else{
              self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Customer failed to update.", status:"warning"});
            }
          });
      } else if (this.$router.currentRoute.name == "newcustomer") {
        this.$store
          .dispatch({
            type: "MDMStore/addCustomer",
            customer: this.customer,
            meters: this.gatewaySelectedMeters.map(m => m.meter.id)
          })
          .then(data => {
            if (data.success) {
              self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Customer created successfully", status:"success"});
              self.$router.push({path:"/customers/customer/"+data.custId})
            }else{
              self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Failed to create customer.", status:"warning"});
            }
          });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    gatewayMeterCount(gatewayId) {
      return this.gatewaySelectedMeters.filter(obj => obj.gateway == gatewayId)
        .length;
    },
    onGatewayClick(gatewayId) {
      this.showMetersDialog = true;
      var gatewayMeters = this.$store.getters["MDMStore/getterGatewayMeters"](
        gatewayId
      );
      this.selectedMeters = [];
      this.selectedGateway = gatewayId;
      var gatewaySelectedMeters = this.gatewaySelectedMeters.filter(
        obj => obj.gateway == gatewayId
      );
      this.selectedMeters = gatewaySelectedMeters.map(obj => obj.meter);
      this.dialogMeters = gatewayMeters.filter(
        meter => this.isSelectedMeter(meter) == false
      );
    },
    isSelectedMeter(meter) {
      if (this.selectedMeters.length != 0) {
        return this.selectedMeters.filter(m => m.id == meter.id).length != 0;
      }
      return false;
    },
    onMeterClick(meter) {
      this.gatewaySelectedMeters.push({
        gateway: this.selectedGateway,
        meter: meter
      });
      this.selectedMeters.push(meter);
      this.dialogMeters.splice(
        this.dialogMeters.findIndex(m => m.id == meter.id),
        1
      );
    },
    onMeterClose(meter) {
      this.selectedMeters.splice(
        this.selectedMeters.findIndex(m => m.id == meter.id),
        1
      );
      this.gatewaySelectedMeters.splice(
        this.gatewaySelectedMeters.findIndex(m => m.meter.id == meter.id),
        1
      );
      this.dialogMeters.push(meter);
    },
    saveCustomerMeters() {
      console.log(this.gatewaySelectedMeters);
    }
  }
};
</script>
